import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface EmailState {
  name: string;
  email: string;
  content: string;
}

const initialState: EmailState = {
  name: "",
  email: "",
  content: "",
};

export const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setContent: (state, action: PayloadAction<string>) => {
      state.content = action.payload;
    },
    resetEmailSlice: () => initialState,
  },
});

export const { setName, setEmail, setContent, resetEmailSlice } =
  emailSlice.actions;
export default emailSlice.reducer;
