import Well from "../../shared/Components/Well/Well";

export interface ITodo {
  content: string | React.ReactNode;
  completed: boolean;
}

export const todos: ITodo[] = [
  {
    content: (
      <>
        The mapbox page reloads data slowly such that a user can toggle on and
        off quickly and the map will become out of sync with the UI state. The
        UI state should always be the truth. As such, whenever a refresh request
        is performed, a <Well>hash</Well> should be generated and stored and
        before setting the new data to the <Well>layers</Well> state, we should
        confirm this hash is the same as the one we have. This should be done
        for all visualization controls to ensure the map states in sync with the
        UI controls.
      </>
    ),
    completed: false,
  },
  {
    content:
      "When the Spotify token expires, theres still some reset error showing. We could fix this or we could do the whole OAuth with refresh tokens work.",
    completed: false,
  },
  {
    content:
      "CartographyCrafter needs to have the preview image on the first step and not the second. The second will just be the text parts but still the preview image and chips below because it looks pretty.",
    completed: false,
  },
  {
    content:
      "Poster services need a final filter such as grayscale, golden, etc.",
    completed: false,
  },
  {
    content:
      "Breadcrumbs for nested routes need to fade in such as when we're on a blog or a widget.",
    completed: true,
  },
  {
    content: "Blog on creating TunePosters.",
    completed: false,
  },
  {
    content: "Blog on Cyder.",
    completed: false,
  },
  {
    content: "Blog on GravatarJavaClient.",
    completed: false,
  },
  {
    content:
      "Blog on query string modals, GitHub and npm links, a getting started/tutorial section, and example with how it's used on this website.",
    completed: false,
  },
  {
    content:
      "By netid street-view viewing needs to have a link to open it in google maps. While here, also crop the image to not show google at the bottom.",
    completed: false,
  },
];
