import { AlbumDetailsTrack } from "./../interfaces/AlbumDetailsTrack";
import AlbumDetailsAndArtwork from "../../Components/Steps/SearchAlbumsStep/interfaces/AlbumDetailsAndArtwork";
import { bulletPoint } from "../../../../shared/Helpers/StringHelpers";

const msInSecond = 1000;
const secondsInHour = 3600;
const minutesInHour = 60;
const separator = ":";

function padTimeSection(section: number) {
  return String(section).padStart(2, "0");
}

export function msToSpotifyTime(ms: number): string {
  const seconds = Math.floor(ms / msInSecond);
  const hours = Math.floor(seconds / secondsInHour);
  const minutes = Math.floor((seconds % secondsInHour) / minutesInHour);
  const remainingSeconds = seconds % minutesInHour;

  const formattedHours = hours > 0 ? hours + separator : "";
  const formattedMinutes = hours > 0 ? padTimeSection(minutes) : minutes;
  const formattedSeconds =
    hours > 0 || minutes > 0
      ? padTimeSection(remainingSeconds)
      : remainingSeconds;

  const ret = formattedHours + formattedMinutes + separator + formattedSeconds;
  if (ret.startsWith(separator)) return ret.slice(1);
  return ret;
}

export function formatDurationVerbose(ms: number): string {
  const seconds = Math.floor(ms / msInSecond);
  const hours = Math.floor(seconds / secondsInHour);
  const minutes = Math.floor((seconds % secondsInHour) / minutesInHour);
  const remainingSeconds = seconds % minutesInHour;

  let parts = [];

  if (hours > 0) {
    parts.push(`${hours} hr`);
    if (minutes > 0) parts.push(`${minutes} min`);
  } else if (minutes > 0) {
    parts.push(`${minutes} min`);
    if (remainingSeconds > 0) parts.push(`${remainingSeconds} sec`);
  } else {
    parts.push(`${remainingSeconds} sec`);
  }

  return parts.slice(0, 2).join(" ");
}

export function getAlbumFormattedLength(
  albumDetailsAndArtwork: AlbumDetailsAndArtwork
) {
  let sumMs = 0;
  albumDetailsAndArtwork.albumDetails.tracks.forEach((track) => {
    sumMs += track.trackMsDuration;
  });
  return formatDurationVerbose(sumMs);
}

export function tracksToPosterString(
  tracks: AlbumDetailsTrack[],
  showSongLengths: boolean,
  showTrackNumbers: boolean
): string {
  const trackStrings: string[] = [];

  tracks.forEach((track) => {
    const formattedTrackLength = msToSpotifyTime(track.trackMsDuration);
    const suffix = showSongLengths ? " (" + formattedTrackLength + ")" : "";
    const prefix = showTrackNumbers ? track.trackNumber + ". " : "";
    trackStrings.push(`${prefix} ${track.trackName} ${suffix}`.trim());
  });

  return trackStrings.join(` ${bulletPoint} `);
}
