import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useOutletContext } from "react-router-dom";

interface ScrollPositionContextType {
  setPrimaryScrollViewRef: (node: HTMLDivElement) => void;
  resetScrollPosition: () => void;
  currentScrollPosition: number;
  scrollableHeight: number;
  isLoading: boolean;
}

const ScrollPositionContext = createContext<
  ScrollPositionContextType | undefined
>(undefined);

interface ScrollPositionProvidedProps {
  children: React.ReactNode;
}

const ScrollPositionProvider: React.FC<ScrollPositionProvidedProps> = ({
  children,
}) => {
  // Hooks
  const location = useLocation();
  const isInOutlet = useOutletContext();

  // State
  const [currentScrollPosition, setCurrentScrollPosition] = useState<number>(0);
  const [scrollableHeight, setScrollableHeight] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Refs
  const primaryScrollViewRef = useRef<HTMLDivElement | null>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const resetScrollPosition = useCallback(() => {
    setIsLoading(true);
    if (primaryScrollViewRef.current) {
      primaryScrollViewRef.current.scrollTo({ top: 0, behavior: "smooth" });
      setCurrentScrollPosition(0);
    }

    // Use a combination of setTimeout and requestAnimationFrame for more reliable reset
    setTimeout(() => {
      requestAnimationFrame(() => {
        if (primaryScrollViewRef.current) {
          primaryScrollViewRef.current.scrollTo({ top: 0, behavior: "smooth" });
          setCurrentScrollPosition(0);

          // Check if content has actually loaded
          const checkContentLoaded = () => {
            if (
              primaryScrollViewRef.current &&
              primaryScrollViewRef.current.scrollHeight > window.innerHeight
            ) {
              setIsLoading(false);
            } else {
              requestAnimationFrame(checkContentLoaded);
            }
          };
          checkContentLoaded();
        } else {
          setIsLoading(false);
        }
      });
    }, 100);
  }, []);

  const setPrimaryScrollViewRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      primaryScrollViewRef.current = node;

      const handleScroll = () => {
        setCurrentScrollPosition(node.scrollTop);
      };

      const updateScrollableHeight = () => {
        setScrollableHeight(node.scrollHeight - node.clientHeight);
      };

      node.addEventListener("scroll", handleScroll);

      // Use ResizeObserver instead of window resize event
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
      resizeObserverRef.current = new ResizeObserver(updateScrollableHeight);
      resizeObserverRef.current.observe(node);

      // Initial update
      updateScrollableHeight();

      return () => {
        node.removeEventListener("scroll", handleScroll);
        if (resizeObserverRef.current) {
          resizeObserverRef.current.disconnect();
        }
      };
    }
  }, []);

  const getFirstPathnamePart = useCallback((locationPathname: string) => {
    return locationPathname.split("/")[1];
  }, []);

  useEffect(() => {
    if (location.pathname.includes("services")) return; // edge cases
    resetScrollPosition();
  }, [location.pathname, getFirstPathnamePart, resetScrollPosition]);

  return (
    <ScrollPositionContext.Provider
      value={{
        setPrimaryScrollViewRef,
        resetScrollPosition,
        currentScrollPosition,
        scrollableHeight,
        isLoading,
      }}
    >
      {children}
    </ScrollPositionContext.Provider>
  );
};

export function useScrollPositionContext() {
  const context = useContext(ScrollPositionContext);
  if (!context) {
    throw new Error(
      "useScrollPositionContext must be used within a ScrollPositionProvider"
    );
  }
  return context;
}

export default ScrollPositionProvider;
