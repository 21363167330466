import { Box, Typography } from "@mui/material";
import CommonRouteContainer from "../../shared/Components/Container/Route/CommonRouteContainer";
import SectionContainer from "../../shared/Components/Container/SectionContainer";
import HoverableLink from "../../shared/Components/Links/HoverableLink";
import CommonDescription from "../../shared/Components/Text/CommonDescription";
import CommonHeader from "../../shared/Components/Text/CommonHeader";
import { generalMaxWidth } from "../../shared/Constants/CommonConstants";
import { getGreeting } from "../../shared/Helpers/GeneralHelpers";

export default function ForEmployers() {
  return (
    <CommonRouteContainer titlePrefix="For employers">
      <CommonHeader>For employers</CommonHeader>
      <SectionContainer customBoxJustification="center">
        <CommonDescription>
          Greetings, salutations, and {getGreeting()} potential future employer!
          If you would like a copy of my current resume, I no longer offer it as
          a direct download on my website. Please{" "}
          <HoverableLink linkTo={`mailto:nathan.cheshire@proton.me`}>
            send me an email
          </HoverableLink>{" "}
          or <HoverableLink linkTo={`tel:9855030520`}>call me</HoverableLink>{" "}
          and I would be happy to provide a copy of my most recent resume as
          well as any other information you might desire. Aside from my
          employment history, I strongly feel you can get a better feel for my
          skills and prowess using this website as that is the intended purpose.
          Check out the{" "}
          <HoverableLink linkTo="/blogs" openInNewTab={false}>
            blogs
          </HoverableLink>{" "}
          and{" "}
          <HoverableLink linkTo="/widgets" openInNewTab={false}>
            widgets
          </HoverableLink>{" "}
          sections for some really cool content I have created and made
          available for your enjoyment.
        </CommonDescription>
        <CommonDescription>
          Thank you for your time in reading this, checking out my website, and
          contacting me. I look forward to hearing from you to discuss job
          opportunities and learn more about the company.
        </CommonDescription>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            width: "100%",
            maxWidth: generalMaxWidth,
          }}
        >
          <CommonDescription>Best regards,</CommonDescription>
          <Typography
            sx={{
              fontFamily: "Allison",
              fontWeight: "bold",
              fontSize: "36px",
            }}
          >
            Nathan Cheshire
          </Typography>
        </Box>
      </SectionContainer>
    </CommonRouteContainer>
  );
}
