import React, { createContext, useCallback, useContext, useState } from "react";
import { StopFunction } from "../../components/TunePosters/Components/Steps/SearchAlbumsStep/interfaces/StopFunction";

interface AudioContextType {
  registerAudio: (id: string, stopFunc: StopFunction) => void;
  deregisterAudio: (id: string) => void;
  stopAllPreviewAudios: () => void;
}

const AudioContext = createContext<AudioContextType | undefined>(undefined);

interface AudioProviderProps {
  children: React.ReactNode;
}

export default function AudioProvider({ children }: AudioProviderProps) {
  // State
  const [activeAudios, setActiveAudios] = useState<Map<string, StopFunction>>(
    new Map()
  );

  const stopAllPreviewAudios = useCallback(() => {
    activeAudios.forEach((stop: StopFunction) => stop());
  }, [activeAudios]);

  const registerAudio = useCallback((id: string, stopFunc: StopFunction) => {
    setActiveAudios((prev) => new Map(prev).set(id, stopFunc));
  }, []);

  const deregisterAudio = useCallback((id: string) => {
    setActiveAudios((prev) => {
      const newMap = new Map(prev);
      newMap.delete(id);
      return newMap;
    });
  }, []);

  return (
    <AudioContext.Provider
      value={{
        registerAudio,
        deregisterAudio,
        stopAllPreviewAudios,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
}

export function useAudioContext() {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error("useAudioContext must be used within an AudioProvider");
  }
  return context;
}
