import { useModal } from "query-string-modals";
import { ReactComponent as ReduxSvg } from "../../assets/svgs/Redux.svg";
import SvgButton from "../../shared/Components/Buttons/SvgButton";

export default function ReduxButton() {
  const { openModal } = useModal();

  return (
    <SvgButton
      text={"Reset slices"}
      fontSize="13px"
      width="200px"
      svg={
        <ReduxSvg
          style={{
            width: "40px",
            height: "40px",
            padding: "5px",
          }}
        />
      }
      onClick={() => openModal("reset-redux")}
    />
  );
}
