import { Alert, AlertColor, Box } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import { navbarHeight } from "../../components/Navigation/NavbarConstants";

interface AlertContextState {
  alertMessage: string;
  severity: AlertColor;
  showSuccessAlert: (message: string) => void;
  showInfoAlert: (message: string) => void;
  showWarningAlert: (message: string) => void;
  showErrorAlert: (message: string) => void;
  hideAlert: () => void;
}

const AlertContext = createContext<AlertContextState | undefined>(undefined);

interface AlertProviderProps {
  children: React.ReactNode;
}

export function AlertProvider({ children }: AlertProviderProps) {
  // Constants
  const navbarBelowOffset = 20;
  const visibleFor = 5000;

  // State
  const [state, setState] = useState<{
    alertMessage: string;
    severity: AlertColor;
    showAlert: boolean;
  }>({
    alertMessage: "",
    severity: "error",
    showAlert: false,
  });

  // Refs
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  function showAlert(alertMessage: string, severity: AlertColor) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setState({ alertMessage, severity, showAlert: true });

    timeoutRef.current = setTimeout(() => hideAlert(), visibleFor);
  }

  function showSuccessAlert(alertMessage: string) {
    showAlert(alertMessage, "success");
  }

  function showInfoAlert(alertMessage: string) {
    showAlert(alertMessage, "info");
  }

  function showWarningAlert(alertMessage: string) {
    showAlert(alertMessage, "warning");
  }

  function showErrorAlert(alertMessage: string) {
    showAlert(alertMessage, "error");
  }

  function hideAlert() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setState((prev) => ({ ...prev, showAlert: false }));
  }

  return (
    <AlertContext.Provider
      value={{
        ...state,
        showSuccessAlert,
        showInfoAlert,
        showWarningAlert,
        showErrorAlert,
        hideAlert,
      }}
    >
      {children}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: navbarHeight + navbarBelowOffset,
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Alert
          severity={state.severity}
          onClose={hideAlert}
          sx={{
            opacity: state.showAlert ? 1 : 0,
            transition: "opacity 300ms",
            borderRadius: "200px",
            zIndex: 1000,
          }}
        >
          {state.alertMessage}
        </Alert>
      </Box>
    </AlertContext.Provider>
  );
}

export function useAlert() {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
}
