import { Box, Typography } from "@mui/material";

import { offWhite, orange } from "../../shared/Constants/Colors";
import LaunchIcon from "@mui/icons-material/Launch";
import { Link } from "react-router-dom";

interface Props {
  text: string;
  link?: string;
}

export default function FooterLaunch({ text, link }: Props) {
  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        color: offWhite,
        transition: "all 0.15s linear",
        cursor: "pointer",
        "&:hover": {
          color: orange,
        },
      }}
    >
      <Typography
        sx={{
          fontFamily: "Raleway",
          fontSize: "15px",
          color: "inherit",
          marginTop: "0px",
        }}
      >
        {text}
      </Typography>
      <LaunchIcon
        sx={{
          width: "20px",
        }}
      />
    </Box>
  );

  if (link) return <Link to={link}>{content}</Link>;
  return content;
}
