import { Typography } from "@mui/material";
import { offWhite } from "../../shared/Constants/Colors";

interface FooterTextProps {
  children: string | React.ReactNode;
  textAlign?: "center" | "left" | "right";
}

export default function FooterText({
  children,
  textAlign = "center",
}: FooterTextProps) {
  return (
    <Typography
      sx={{
        color: offWhite,
        fontFamily: "Raleway",
        fontSize: "15px",
        textAlign: textAlign,
        maxWidth: "80%",
      }}
    >
      {children}
    </Typography>
  );
}
