import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AlbumAndArtistSuggestion } from "../components/TunePosters/helpers/spotify/AlbumAndArtistSuggestion";

interface SearchAlbumsState {
  albumName: string;
  artistName: string;
  suggestions: AlbumAndArtistSuggestion[];
}

const initialState: SearchAlbumsState = {
  albumName: "",
  artistName: "",
  suggestions: [],
};

export const searchAlbumsSlice = createSlice({
  name: "searchAlbums",
  initialState,
  reducers: {
    setAlbumName: (state, action: PayloadAction<string>) => {
      state.albumName = action.payload;
    },
    setArtistName: (state, action: PayloadAction<string>) => {
      state.artistName = action.payload;
    },
    setSuggestions: (
      state,
      action: PayloadAction<AlbumAndArtistSuggestion[]>
    ) => {
      state.suggestions = action.payload;
    },
    resetSearchAlbumsSlice: () => initialState,
  },
});

export const {
  setAlbumName,
  setArtistName,
  setSuggestions,
  resetSearchAlbumsSlice,
} = searchAlbumsSlice.actions;
export default searchAlbumsSlice.reducer;
