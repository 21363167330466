import { useModal } from "query-string-modals";
import StandardModal from "../../../shared/Components/Modal/StandardModal";

export default function RoutesModal() {
  const { closeModal } = useModal();

  return (
    <StandardModal
      title={"Routes"}
      cancelButtonText={"Close"}
      cancelButtonAction={() => closeModal()}
      descrpitionText="Routes are paths from a particular student's Banner public address for a given semester to the university. You can toggle on and off specific states as well as search for specific students by netid. If you make an error by enabling too many states at one time such that the map continues to crash, see the debug page to reset the proper redux slice."
    />
  );
}
