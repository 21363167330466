/**
 * The screen width at which a screen is counted is being large.
 */
export const maxSmallScreenWidth = 950;

/**
 * The screen width at which a screen is counted as being medium.
 */
export const mediumScreenWidth = 600;

/**
 * The screen width at which a screen is larger than an iPhone SE.
 */
export const maxSeScrenWidth = 400;

export const generalMaxWidth = "800px";
