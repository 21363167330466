import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import AlbumDetailsAndArtwork from "../components/TunePosters/Components/Steps/SearchAlbumsStep/interfaces/AlbumDetailsAndArtwork";

interface ChooseAlbumState {
  albumDetailsAndArtworks: AlbumDetailsAndArtwork[];
  chosenAlbumDetailsAndArtwork: AlbumDetailsAndArtwork | undefined;
  showAlbums: boolean;
  showSingles: boolean;
  showCompilations: boolean;
}

const initialState: ChooseAlbumState = {
  albumDetailsAndArtworks: [],
  chosenAlbumDetailsAndArtwork: undefined,
  showAlbums: true,
  showSingles: true,
  showCompilations: true,
};

export const chooseAlbumSlice = createSlice({
  name: "chooseAlbum",
  initialState,
  reducers: {
    setAlbumDetailsAndArtworks: (
      state,
      action: PayloadAction<AlbumDetailsAndArtwork[]>
    ) => {
      state.albumDetailsAndArtworks = action.payload;
    },
    setChosenAlbumDetailsAndArtwork: (
      state,
      action: PayloadAction<AlbumDetailsAndArtwork>
    ) => {
      state.chosenAlbumDetailsAndArtwork = action.payload;
    },
    setShowAlbums: (state, action: PayloadAction<boolean>) => {
      state.showAlbums = action.payload;
    },
    setShowSingles: (state, action: PayloadAction<boolean>) => {
      state.showSingles = action.payload;
    },
    setShowCompilations: (state, action: PayloadAction<boolean>) => {
      state.showCompilations = action.payload;
    },
    resetChooseAlbumSlice: () => initialState,
  },
});

export const {
  setAlbumDetailsAndArtworks,
  setChosenAlbumDetailsAndArtwork,
  setShowAlbums,
  setShowSingles,
  setShowCompilations,
  resetChooseAlbumSlice,
} = chooseAlbumSlice.actions;
export default chooseAlbumSlice.reducer;
