import { Typography } from "@mui/material";

interface DebugInformationProps {
  children: string | React.ReactNode;
}

export default function DebugInformation({ children }: DebugInformationProps) {
  return (
    <li>
      <Typography
        component="span"
        sx={{
          textAlign: "start",
          width: "100%",
          fontFamily: "Prompt",
          fontSize: "16px",
        }}
      >
        {children}
      </Typography>
    </li>
  );
}
