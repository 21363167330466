import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface LocationState {
  location: string;
  rawStaticMap: string;
}

const initialState: LocationState = {
  location: "",
  rawStaticMap: "",
};

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocation: (state, action: PayloadAction<string>) => {
      state.location = action.payload;
    },
    setRawStaticMap: (state, action: PayloadAction<string>) => {
      state.rawStaticMap = action.payload;
    },
    resetLocationSlice: () => initialState,
  },
});

export const { setLocation, setRawStaticMap, resetLocationSlice } =
  locationSlice.actions;
export default locationSlice.reducer;
