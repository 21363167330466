import { Box, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import CommonDescription from "../../shared/Components/Text/CommonDescription";
import CommonHeader from "../../shared/Components/Text/CommonHeader";
import { useRowColumn } from "../../shared/Hooks/useRowColumn";

export default function PersistGateLoading() {
  // Constants
  const minVisibleTime = 1000;

  // State
  const [showLoading, setShowLoading] = useState<boolean>(false);

  // Hooks
  const flexDirection = useRowColumn(500);

  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), minVisibleTime);
    return () => clearTimeout(timer);
  }, []);

  if (!showLoading) return <></>;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "calc(100vh - 20px)",
        paddingY: "0px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <CommonHeader>Getting things ready for you</CommonHeader>
        <Box
          sx={{
            display: "flex",
            flexDirection: flexDirection,
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={30} />
          <CommonDescription>Hang tight...</CommonDescription>
        </Box>
      </Box>
    </Box>
  );
}
