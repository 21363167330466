import { useState } from "react";
import { Link } from "react-router-dom";

/**
 * The props accepted by this component.
 */
export interface HoverableLinkProps {
  children: string | React.ReactNode;
  linkTo: string;
  textColor?: string;
  hoverColor?: string;
  openInNewTab?: boolean;
  allowWordBreak?: boolean;
}

const defaultTextColor = "#626262";
const defaultHoverColor = "#457BB7";

/**
 * A react-router link component wrapping the provided text which links to the provided link.
 * The parent styles are used for font weight, style, size, and initial text color.
 */
export default function HoverableLink({
  children,
  linkTo,
  textColor = defaultTextColor,
  hoverColor = defaultHoverColor,
  openInNewTab = true,
  allowWordBreak = false,
}: HoverableLinkProps) {
  // State
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <Link
      to={linkTo}
      style={{
        color: hovered ? hoverColor : textColor,
        transition: "all 0.3s linear",
        wordBreak: allowWordBreak ? "break-all" : "normal",
        fontWeight: "bold",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      {...(openInNewTab ? { target: "_blank", rel: "noopener" } : {})}
    >
      {children}
    </Link>
  );
}
