import { MenuItem } from "./MenuItem";

export const menuItems: MenuItem[] = [
  {
    label: "Home",
    urlPath: "/home",
  },
  {
    label: "Services",
    urlPath: "/services",
  },
  {
    label: "Widgets",
    urlPath: "/widgets",
  },
  {
    label: "Blogs",
    urlPath: "/blogs",
  },
  {
    label: "Contact",
    urlPath: "/contact",
  },
  {
    label: "GitHub",
    urlPath: "/github",
  },
  {
    label: "Employers",
    urlPath: "/for-employers",
  },
];
