import { lazy, Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import ForEmployers from "../components/ForEmployers/ForEmployers";
import { viewLicenseAgreementUrlSuffix } from "../components/Services/ServiceConstants";
import Todo from "../components/Todo/Todo";
import PersistGateLoading from "../redux/PersistGate/PersistGateLoading";
import { useDocumentTitle } from "../shared/Context/DocumentTitleContext";

// Lazy loaded components
const BlogPost = lazy(() => import("../components/Blogs/BlogPost"));
const Blogs = lazy(() => import("../components/Blogs/Blogs"));
const Contact = lazy(() => import("../components/Contact/Contact"));
const Credits = lazy(() => import("../components/Credits/Credits"));
const Eula = lazy(() => import("../components/Eula/Eula"));
const GitHub = lazy(() => import("../components/GitHub/GitHub"));
const Home = lazy(() => import("../components/Home/Home"));
const LinkedInRedirector = lazy(
  () => import("../components/LinkedIn/LinkedInRedirector")
);
const MapboxMap = lazy(() => import("../components/Maps/MapboxMap"));
const NotFound = lazy(() => import("../components/NotFound/NotFound"));
const Services = lazy(() => import("../components/Services/Services"));
const SpotifyAuthenticationCallback = lazy(
  () =>
    import(
      "../components/TunePosters/Components/Spotify/SpotifyAuthenticationCallback"
    )
);
const WidgetContainer = lazy(
  () => import("../components/Widgets/WidgetContainer")
);
const Widgets = lazy(() => import("../components/Widgets/Widgets"));
const LicenseAgreementPreview = lazy(
  () => import("../components/Services/LicenseAgreementPreview")
);

export default function WebsiteRoutes() {
  // Hooks
  const location = useLocation();

  // Context
  const { setDocumentTitle } = useDocumentTitle();

  // Effects
  useEffect(() => setDocumentTitle("Nathan Cheshire"), [setDocumentTitle]);

  return (
    <Suspense fallback={<PersistGateLoading />}>
      <Routes location={location}>
        {/* Standard routes navigatable via the menu */}
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<Home />} />
        <Route path="/services" element={<Services />}>
          <Route
            path={`/services/${viewLicenseAgreementUrlSuffix}`}
            element={<LicenseAgreementPreview />}
          />
        </Route>
        <Route path="/widgets" element={<Widgets />} />
        <Route path="/widgets/:widgetId" element={<WidgetContainer />} />
        <Route path="/eula" element={<Eula />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:blogId" element={<BlogPost />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/credits" element={<Credits />} />
        <Route path="/github" element={<GitHub />} />
        <Route path="/map" element={<MapboxMap />} />
        <Route path="/for-employers" element={<ForEmployers />} />
        {/* hidden routes */}
        <Route path="/todo" element={<Todo />} />
        {/* Spotify callback */}
        <Route
          path="/spotify-authentication-callback"
          element={<SpotifyAuthenticationCallback />}
        />
        {/* Redirectors */}
        <Route path="/linkedin" element={<LinkedInRedirector />} />
        {/* Fallback */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
