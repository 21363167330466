import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, IconButton, Zoom } from "@mui/material";
import { useEffect, useState } from "react";
import { offBlack, orange } from "../../shared/Constants/Colors";
import { commonBoxShadow } from "../../shared/Constants/StylingConstants";
import { useScrollPositionContext } from "../../shared/Context/ScrollPositionContext";
import { footerHeight } from "../Footer/FooterConstants";

export default function BackToTop() {
  // Constants
  const showButtonWhenPastScrollPosition = 100;
  const buttonId = "backToTopButton";

  // State
  const [buttonHeight, setButtonHeight] = useState<number>(0);

  // Context
  const { resetScrollPosition, currentScrollPosition } =
    useScrollPositionContext();

  // Effects
  useEffect(() => {
    const buttonElement = document.getElementById(buttonId);
    if (!buttonElement) return;
    setButtonHeight(buttonElement.clientHeight);
  }, []);

  return (
    <Zoom in={currentScrollPosition > showButtonWhenPastScrollPosition}>
      <Box
        sx={{
          position: "fixed",
          zIndex: 10,
          alignItems: "center",
          display: "flex",
          justifyContent: "end",
          marginX: "10px",
          borderRadius: "200px",
          gap: "20px",
          right: 10,
          bottom: `calc(${footerHeight}px / 2 - ${buttonHeight}px / 2 - 2px)`,
        }}
        id={buttonId}
      >
        <Box
          sx={{
            backgroundColor: orange,
            borderRadius: "200px",
            boxShadow: commonBoxShadow,
            backdropFilter: "blur(10px)",
          }}
        >
          <IconButton onClick={resetScrollPosition}>
            <KeyboardArrowUpIcon
              sx={{
                color: offBlack,
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Zoom>
  );
}
