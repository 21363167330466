import axios, { AxiosResponse } from "axios";
import { euclideanColorDistance, rgbToHex } from "../color/ColorHelpers";
import { Scale } from "../google/Scale";
import CanvasAndContext from "./CanvasAndContext";
import { ImageSize } from "./ImageSize";
import { getCropFactorForScale } from "./ScaleCropFactor";

export async function readBlobbedImageFromUrl(
  imageUrl: string
): Promise<AxiosResponse<any, any>> {
  return axios.get(imageUrl, { responseType: "blob" });
}

/**
 * Reads an image from the provided URl and crops the bottom pixels to remove the Google maps watermark.
 * This assumes the image was requested with scale of 2 meaning the bottom 44 pixels will be cropped off.
 *
 * @param imageUrl the url of the image to read and crop
 * @param requestedWidth the requested width
 * @param requestedHeight the requested height
 * @returns the image cropped to the provided constraints
 */
export async function readScaleAndCropImage(
  imageUrl: string,
  requestedWidth: number,
  requestedHeight: number
): Promise<string | undefined> {
  return new Promise(async (resolve, reject) => {
    readBlobbedImageFromUrl(imageUrl)
      .then((response) => {
        const image = new Image();
        image.src = URL.createObjectURL(response.data);

        image.onload = () => {
          const croppedImage = scaleAndCropImage(
            image,
            getCropFactorForScale(Scale.TWO),
            requestedWidth,
            requestedHeight
          );
          resolve(croppedImage);
        };
        image.onerror = () => {
          reject(new Error("Error loading image"));
        };
      })
      .catch((error: any) => {
        console.error(
          "Error reading blobbed image from url:",
          imageUrl,
          "error:",
          error
        );
        reject(error);
      });
  });
}

/**
 * Crops the provided number of bottom pixels off of the image and crops the resulting
 * image to the provided requested width and height. The cropped image will be as much
 * in the center of the starting image as is possible.
 *
 * @param image the image to crop
 * @param cropBottomPixels the number of pixels to crop off of the bottom
 * @param requestedWidth the requested width of the image
 * @param requestedHeight the requested height of the image
 * @returns the cropped and scaled image
 */
function scaleAndCropImage(
  image: HTMLImageElement,
  cropBottomPixels: number,
  requestedWidth: number,
  requestedHeight: number
) {
  // setup canvas
  const canvas = document.createElement("canvas");
  const canvasContext = canvas.getContext("2d");
  if (!canvasContext) return;
  canvas.width = requestedWidth;
  canvas.height = requestedHeight;

  const originalCroppedHeight = image.height - cropBottomPixels;
  const scaleX = image.width / requestedWidth;
  const scaleY = originalCroppedHeight / requestedHeight;
  const scale = Math.min(scaleX, scaleY);
  const scaledWidth = requestedWidth * scale;
  const scaledHeight = requestedHeight * scale;

  // Calculate the starting x and y coordinates to center the crop
  const startX = (image.width - scaledWidth) / 2;
  const startY = (originalCroppedHeight - scaledHeight) / 2;

  canvasContext.drawImage(
    image,
    startX,
    startY,
    scaledWidth,
    scaledHeight,
    0,
    0,
    requestedWidth,
    requestedHeight
  );

  return canvas.toDataURL();
}

export async function getImageSize(base64Image: string): Promise<ImageSize> {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => resolve({ width: image.width, height: image.height });
    image.onerror = (error: any) => {
      console.error(error);
      reject(new Error("Could not load image"));
    };
    image.src = base64Image;
  });
}

/**
 * Returns the top colors from the provided image.
 * Note the second parameter is meerly a bound. If there aren't as many colors as requested,
 * then the maximum possible will be returned.
 *
 * @param base64Image the image
 * @param numberTopColors the number of top colors to return
 * @returns the top colors from the provided image
 */
export async function getTopColors(
  base64Image: string,
  numberTopColors: number,
  threshold: number = startingThreshold
): Promise<string[]> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = base64Image;
    image.onload = () => {
      const context = new CanvasAndContext({
        width: image.width,
        height: image.height,
      }).getContext();
      context.drawImage(image, 0, 0);
      const imageData = context.getImageData(
        0,
        0,
        image.width,
        image.height
      ).data;

      const colorCount = new Map<string, number>();
      for (let i = 0; i < imageData.length; i += 4) {
        const hexColor = rgbToHex(
          imageData[i],
          imageData[i + 1],
          imageData[i + 2]
        );
        colorCount.set(hexColor, (colorCount.get(hexColor) || 0) + 1);
      }

      let sortedColors = Array.from(colorCount.entries())
        .sort((a, b) => b[1] - a[1])
        .map((entry) => entry[0]);

      const distinctColors = filterDistinctColors(
        sortedColors,
        numberTopColors,
        threshold
      );

      resolve(distinctColors);
    };

    image.onerror = (error) => reject(error);
  });
}

const startingThreshold = 80;
const thresholdStep = 10;

function filterDistinctColorsWithThreshold(
  colors: string[],
  maxColors: number,
  threshold: number
): string[] {
  let distinctColors: string[] = [];

  for (const color of colors) {
    if (distinctColors.length >= maxColors) break;

    let isDistinct = true;
    for (const distinctColor of distinctColors) {
      if (euclideanColorDistance(color, distinctColor) < threshold) {
        isDistinct = false;
        break;
      }
    }

    if (isDistinct) distinctColors.push(color);
  }

  return distinctColors;
}

function filterDistinctColors(
  colors: string[],
  maxColors: number,
  threshold: number = startingThreshold
): string[] {
  while (threshold >= thresholdStep) {
    const filteredColors = filterDistinctColorsWithThreshold(
      colors,
      maxColors,
      threshold
    );
    if (filteredColors.length !== maxColors) threshold -= thresholdStep;
    else return filteredColors;
  }

  return filterDistinctColorsWithThreshold(colors, maxColors, 0);
}
