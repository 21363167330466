export const fontSizeMinScalar = 0.03;
export const fontSizeMaxScalar = 0.1;
export const fontSizeScalarStep = 0.0001;
export const defaultFontSizeScalar = 0.06;

export const borderPaddingMinScalar = 2;
export const borderPaddingMaxScalar = 8;
export const borderPaddingScalarStep = 0.01;
export const defaultBorderPaddingScalar = 4;

export const borderRadiusMin = 0;
export const borderRadiusMax = 200;
export const borderRadiusStep = 1;
export const defaultBorderRadius = 30;

export const dominantColorsStep = 1;
export const minDominantColorsValue = 10;
export const maxDominantColorsValue = 100;

export const metaDataSongsPaddingStep = 1;
export const metaDataSongsPaddingMin = 0;
export const metaDataSongsPaddingMax = 300;
