import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { offBlack, offWhite } from "../../Constants/Colors";
import { useMinWidth } from "../../Hooks/useMinWidth";
import { useRowColumn } from "../../Hooks/useRowColumn";
import CommonButton from "../Buttons/CommonButton";
import TextButton from "../Buttons/TextButton";
import { scrollbarWidth } from "../../../app/ScrollConstants";

interface StandardModalProps {
  title: string;
  descrpitionText?: string;
  children?: React.ReactNode;
  primaryButtonDisabled?: boolean;
  primaryButtonText?: string;
  primaryButtonAction?: () => void;
  cancelButtonText: string;
  cancelButtonAction: () => void;
  maxWidth?: string;
  onChoice?: () => void;
}

export default function StandardModal({
  title,
  descrpitionText,
  children,
  primaryButtonDisabled,
  primaryButtonText,
  primaryButtonAction,
  cancelButtonText,
  cancelButtonAction,
  maxWidth,
  onChoice,
}: StandardModalProps) {
  // Constants
  const transitionPoint = 300;

  // Hooks
  const rowActions = useMinWidth(transitionPoint);

  return (
    <Dialog
      sx={{
        "& .MuiPaper-root": {
          backgroundColor: "white",
          borderRadius: "16px",
          maxWidth: maxWidth,
        },
      }}
      open={true}
    >
      <DialogTitle>
        <Typography
          sx={{
            userSelect: "none",
            fontFamily: "Raleway",
            fontSize: "24px",
            color: offBlack,
          }}
        >
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          "&::-webkit-scrollbar": {
            color: offBlack,
            width: `${scrollbarWidth}px`,
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: offBlack,
            borderRadius: `${scrollbarWidth / 2}px`,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
          scrollbarWidth: "thin",
          scrollbarColor: `${offBlack} transparent`,
        }}
      >
        {descrpitionText && (
          <Typography
            sx={{
              fontSize: "16px",
              fontFamily: "Roboto",
              color: "#656565",
            }}
          >
            {descrpitionText}
          </Typography>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: useRowColumn(transitionPoint),
            alignItems: "center",
            justifyContent: rowActions ? "end" : "center",
            width: "100%",
            gap: "10px",
          }}
        >
          {primaryButtonText && (
            <CommonButton
              text={primaryButtonText}
              disabled={primaryButtonDisabled}
              onClick={() => {
                primaryButtonAction?.();
                onChoice?.();
              }}
              width="auto"
            />
          )}
          <TextButton
            onClick={() => {
              cancelButtonAction?.();
              onChoice?.();
            }}
            name={cancelButtonText}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
}
