import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface MetaState {
  mapStep: number;
  tuneStep: number;
}

const initialState: MetaState = {
  mapStep: 0,
  tuneStep: 0,
};

export const metaSlice = createSlice({
  name: "meta",
  initialState,
  reducers: {
    setMapStep: (state, action: PayloadAction<number>) => {
      state.mapStep = action.payload;
    },
    setTuneStep: (state, action: PayloadAction<number>) => {
      state.tuneStep = action.payload;
    },
    resetMetaSlice: () => initialState,
  },
});

export const { setMapStep, setTuneStep, resetMetaSlice } = metaSlice.actions;
export default metaSlice.reducer;
