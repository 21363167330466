export function getUserAgent() {
  return navigator.userAgent;
}

export function likelyOnMobile() {
  const userAgent = getUserAgent().toLowerCase();
  const isMobile =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(
      userAgent
    );
  return isMobile;
}

export function getDevicePixelRatio() {
  return window.devicePixelRatio;
}

export function getBrowserLanguage() {
  return navigator.language;
}

export function getLocalTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}
