// Constants
const LEADING_HASH = "#";
const HEX_BASE = 16;

/**
 * Converts the provided r,g,b values to a hex color such as "#ff00ff"
 *
 * @param red the red value
 * @param green the green value
 * @param blue the blue value
 * @returns the hex value of the color
 */
export function rgbToHex(red: number, green: number, blue: number): string {
  const alphaValue = 1 << 24;
  const redValue = red << HEX_BASE;
  const greenValue = green << (HEX_BASE / 2);
  const blueValue = blue;

  const hexColor = (alphaValue + redValue + greenValue + blueValue)
    .toString(HEX_BASE)
    .slice(1);
  return LEADING_HASH + hexColor;
}

/**
 * Sorts the provided hex colors from darkest to lightest.
 * Luminance is used as the heuristic for darkness.
 *
 * @param hexColors the list of hex colors
 * @returns the list of hex colors sorted from darkest to lightest
 */
export function sortHexColorsByLuminance(hexColors: string[]): string[] {
  return hexColors.sort((first, second) => {
    const [firstRed, firstGreen, firstBlue] = hexToRgb(first);
    const [secondRed, secondGreen, secondBlue] = hexToRgb(second);

    const firstLuminance = calculateLuminance(firstRed, firstGreen, firstBlue);
    const secondLuminance = calculateLuminance(
      secondRed,
      secondGreen,
      secondBlue
    );

    return firstLuminance - secondLuminance;
  });
}

export function hexToRgb(hex: string): [number, number, number] {
  let hexValue = hex.startsWith(LEADING_HASH) ? hex.slice(1) : hex;

  let red = parseInt(hexValue.substring(0, 2), HEX_BASE);
  let green = parseInt(hexValue.substring(2, 4), HEX_BASE);
  let blue = parseInt(hexValue.substring(4, 6), HEX_BASE);

  return [red, green, blue];
}

/**
 * Computes the digital luminance of the color via ITU BT.601.
 * This gives more weight to red and blue as opposed to ITU BT.709
 * which biases the green with 0.7152.
 *
 * @param rgb
 * @returns
 */
function calculateLuminance(red: number, green: number, blue: number): number {
  return 0.299 * red + 0.587 * green + 0.114 * blue;
}

/**
 * Computes the distance between the two colors in euclidean space.
 *
 * @param hex the first hex color
 * @param otherHex the second hex color
 * @returns the distance between the two colors in euclidean space
 */
export function euclideanColorDistance(hex: string, otherHex: string): number {
  const rgb = hexToRgb(hex);
  const otherRgb = hexToRgb(otherHex);

  const firstAxisSquared = Math.pow(rgb[0] - otherRgb[0], 2);
  const secondAxisSquared = Math.pow(rgb[1] - otherRgb[1], 2);
  const thirdAxisSquared = Math.pow(rgb[2] - otherRgb[2], 2);

  return Math.sqrt(firstAxisSquared + secondAxisSquared + thirdAxisSquared);
}
