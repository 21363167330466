import { Box } from "@mui/material";
import { useEffect } from "react";
import Navbar from "../components/Navigation/Navbar";
import { navbarHeight } from "../components/Navigation/NavbarConstants";
import { offBlack } from "../shared/Constants/Colors";
import { useAlert } from "../shared/Context/AlertContext";
import { useScrollPositionContext } from "../shared/Context/ScrollPositionContext";
import { scrollbarWidth } from "./ScrollConstants";
import WebsiteRoutes from "./WebsiteRoutes";
import Breadcrumb from "../components/Breadcrumb/Breadcrumbs";
import getBuildHash from "./BuildHelper";

export default function App() {
  // Constants
  const keydown = "keydown";

  // Context
  const { setPrimaryScrollViewRef } = useScrollPositionContext();
  const { showInfoAlert } = useAlert();

  // Effects
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault();
        showInfoAlert("Suppressed save hotkey");
      }
    }

    window.addEventListener(keydown, handleKeyDown);
    return () => window.removeEventListener(keydown, handleKeyDown);
  }, []);

  useEffect(() => {
    console.debug("Website built with <3 by Nate Cheshire");
    console.debug(`Website build: ${getBuildHash()}`);
  }, []);

  return (
    <Box
      ref={setPrimaryScrollViewRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        background: "white",
        position: "relative",
        scrollBehavior: "auto",
        overflowY: "overlay",
        top: 0,
        right: 0,
        "&::-webkit-scrollbar": {
          width: `${scrollbarWidth}px`,
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: offBlack,
          borderRadius: `${scrollbarWidth / 2}px`,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },
        scrollbarWidth: "thin",
        scrollbarColor: `${offBlack} transparent`,
      }}
    >
      <Breadcrumb />
      <Navbar />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          height: `calc(100% - ${navbarHeight}px)`,
        }}
      >
        <WebsiteRoutes />
      </Box>
    </Box>
  );
}
