import { createContext, useContext } from "react";
import { useAppSelector } from "../../redux/ReduxHooks";
import { RootState } from "../../redux/store";

interface SpotifyTokenContextType {
  token: string;
  expiresAt: number;
}

const SpotifyTokenContext = createContext<SpotifyTokenContextType | undefined>(
  undefined
);

interface SpotifyTokenProviderProps {
  children: React.ReactNode;
}

export default function SpotifyTokenProvider({
  children,
}: SpotifyTokenProviderProps) {
  const token = useAppSelector(
    (state: RootState) => state.spotifyAuthentication.token
  );
  const expiresAt = useAppSelector(
    (state: RootState) => state.spotifyAuthentication.expiresAt
  );

  return (
    <SpotifyTokenContext.Provider value={{ token, expiresAt }}>
      {children}
    </SpotifyTokenContext.Provider>
  );
}

export const useSpotifyToken = () => {
  const context = useContext(SpotifyTokenContext);
  if (context === undefined) {
    throw new Error(
      "useSpotifyToken must be used within a SpotifyToken context"
    );
  }
  return context;
};
