import { ManagedModal } from "query-string-modals";
import ZoomLevelsModal from "../../components/CartographyCrafter/components/Modals/ZoomLevelsModal";
import ContactViaEmailModal from "../../components/Contact/ContactViaEmailModal";
import DebugModal from "../../components/Debug/DebugModal";
import ResetReduxModal from "../../components/Debug/ResetReduxModal";
import RoutesModal from "../../components/Maps/DataControls/RoutesModal";

export const modals: ManagedModal[] = [
  {
    modalId: "zoom-levels",
    component: <ZoomLevelsModal />,
    ignoreUrlPattern: /^\/services\/?.*$/,
  },
  {
    modalId: "reset-redux",
    component: <ResetReduxModal />,
  },
  {
    modalId: "routes",
    component: <RoutesModal />,
  },
  {
    modalId: "contact",
    component: <ContactViaEmailModal />,
  },
  {
    modalId: "debug",
    component: <DebugModal />,
  },
];
