import { Box } from "@mui/material";
import { useModal } from "query-string-modals";
import StandardModal from "../../../../shared/Components/Modal/StandardModal";
import { evenMoreOffBlack } from "../../../../shared/Constants/Colors";

export default function ZoomLevelsModal() {
  // Query string modals
  const { closeModal } = useModal();

  interface ZoomLevel {
    level: number;
    description: string;
  }

  const zoomLevels: ZoomLevel[] = [
    {
      level: 1,
      description: "World",
    },
    {
      level: 5,
      description: "Landmesses and Continents",
    },
    {
      level: 10,
      description: "City",
    },
    {
      level: 15,
      description: "Streets",
    },
    {
      level: 20,
      description: "Buildings",
    },
  ];

  const additionalDescriptionChildren = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingTop: "30px",
      }}
    >
      {zoomLevels.map((level) => {
        return (
          <li
            key={level.description}
            style={{
              fontSize: "15px",
              fontFamily: "Roboto",
              color: evenMoreOffBlack,
            }}
          >
            {level.level} = {level.description}
          </li>
        );
      })}
    </Box>
  );

  return (
    <StandardModal
      title={"Zoom levels"}
      cancelButtonText={"Got it"}
      cancelButtonAction={() => closeModal()}
      descrpitionText="Zoom level values are valid in the inclusive range [0, 20]. The general
      results for a few of the values are outlined below. You will most likely
      want to adjust the zoom level to your specific location, however."
    >
      {additionalDescriptionChildren}
    </StandardModal>
  );
}
