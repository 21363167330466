import { combineReducers, configureStore } from "@reduxjs/toolkit";
import localforage from "localforage";
import { persistReducer, persistStore } from "redux-persist";
import exportMapReducer from "./exportMapSlice";
import locationReducer from "./locationSlice";
import mapReducer from "./mapSlice";
import metaReducer from "./metaSlice";
import spotifyAuthenticationReducer from "./spotifyAuthenticationSlice";
import audioReducer from "./audioSlice";
import searchAlbumsReducer from "./searchAlbumsSlice";
import chooseAlbumReducer from "./chooseAlbumSlice";
import tuneFeatureReducer from "./tuneFeatureSlice";
import exportTuneReducer from "./exportTuneSlice";
import emailReducer from "./emailSlice";
import mapVisualizationSlice from "./mapVisualizationSlice";
import preferenceSlice from "./preferenceSlice";

const persistConfig = {
  key: "root",
  storage: localforage,
};

export const reduxSlices = {
  location: locationReducer,
  map: mapReducer,
  exportMap: exportMapReducer,
  meta: metaReducer,
  spotifyAuthentication: spotifyAuthenticationReducer,
  audio: audioReducer,
  searchAlbums: searchAlbumsReducer,
  chooseAlbum: chooseAlbumReducer,
  tuneFeatures: tuneFeatureReducer,
  exportTune: exportTuneReducer,
  email: emailReducer,
  mapVisualizations: mapVisualizationSlice,
  preferenceSlice: preferenceSlice
};

const rootReducer = combineReducers(reduxSlices);

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
