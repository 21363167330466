import styled from "@emotion/styled";

export interface UnselectableInputLabelProps {
  children: React.ReactNode;
}

export default function UnselectableInputLabel({
  children,
}: UnselectableInputLabelProps) {
  const UnselectableInputLabel = styled("label")({
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  });

  return <UnselectableInputLabel>{children}</UnselectableInputLabel>;
}
