import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface AudioState {
  volume: number;
}

const initialState: AudioState = {
  volume: 0.6,
};

export const audioSlice = createSlice({
  name: "audio",
  initialState,
  reducers: {
    setVolume: (state, action: PayloadAction<number>) => {
      state.volume = action.payload;
    },
    resetAudioSlice: () => initialState,
  },
});

export const { setVolume, resetAudioSlice } = audioSlice.actions;
export default audioSlice.reducer;
