import styled from "@emotion/styled";

export interface UnselectableHelperTextProps {
  children: React.ReactNode;
}

export default function UnselectableHelperText({
  children,
}: UnselectableHelperTextProps) {
  const UnselectableHelperText = styled("label")({
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  });

  return <UnselectableHelperText>{children}</UnselectableHelperText>;
}
