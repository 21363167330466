import { Typography } from "@mui/material";

export default function CrumbSeparator() {
  return (
    <Typography
      sx={{
        color: "white",
        fontFamily: "Kanit",
        fontWeight: "bold",
        fontSize: "12px",
        textTransform: "none",
        paddingX: "5px",
        userSelect: "none",
      }}
    >
      /
    </Typography>
  );
}
