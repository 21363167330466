import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TextAlignment = "left" | "right" | "justify";
export const textAlignments: TextAlignment[] = ["left", "right", "justify"];

interface PreferenceState {
  darkMode: boolean;
}

const initialState: PreferenceState = {
  darkMode: true,
};

export const preferenceSlice = createSlice({
  name: "preference",
  initialState,
  reducers: {
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
    },
    resetPreferenceSlice: () => initialState,
  },
});

export const { setDarkMode, resetPreferenceSlice } = preferenceSlice.actions;
export default preferenceSlice.reducer;
