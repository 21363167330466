import dayjs from "dayjs";
import {
  evenMoreOffBlack,
  offBlack,
} from "../../../../../shared/Constants/Colors";
import CanvasAndContext from "../../../../CartographyCrafter/helpers/image/CanvasAndContext";
import {
  EMPTY_IMAGE_SIZE,
  ImageSize,
} from "../../../../CartographyCrafter/helpers/image/ImageSize";
import { MapMarker } from "../../../../CartographyCrafter/helpers/image/MapMarker";
import { AlbumDetailsTrack } from "../../../helpers/interfaces/AlbumDetailsTrack";
import { tracksToPosterString } from "../../../helpers/spotify/SpotifyHelpers";

export const DEFAULT_RELEASE_DATE_FORMAT = "MMMM D, YYYY";

export class TuneMarker extends MapMarker {
  private albumImage: string;
  private tracks: AlbumDetailsTrack[];
  private albumName: string;
  private artistName: string;
  private albumLength: string;
  private releaseDate: string;
  private releaseDateFormat: string;
  private showSongLengths: boolean;
  private showAlbumLength: boolean;
  private tuneFont: string;
  private showTrackNumbers: boolean;
  private artworkSize: ImageSize;

  constructor(
    albumImage: string,
    tracks: AlbumDetailsTrack[],
    albumName: string,
    artistName: string,
    albumLength: string,
    releaseDate: string
  ) {
    super(albumImage);
    this.albumImage = albumImage;
    this.tracks = tracks;
    this.albumName = albumName;
    this.artistName = artistName;
    this.albumLength = albumLength;
    this.releaseDate = releaseDate;
    this.showSongLengths = true;
    this.showAlbumLength = true;
    this.tuneFont = "Oswald";
    this.showTrackNumbers = true;
    this.artworkSize = EMPTY_IMAGE_SIZE;

    this.releaseDateFormat = DEFAULT_RELEASE_DATE_FORMAT;
  }

  setArtworkSize(artworkSize: ImageSize): TuneMarker {
    this.artworkSize = artworkSize;
    return this;
  }

  setShowTrackNumbers(showTrackNumbers: boolean): TuneMarker {
    this.showTrackNumbers = showTrackNumbers;
    return this;
  }

  setReleaseDateFormat(releaseDateFormat: string): TuneMarker {
    this.releaseDateFormat = releaseDateFormat;
    return this;
  }

  setShowSongLengths(showSongLengths: boolean): TuneMarker {
    this.showSongLengths = showSongLengths;
    return this;
  }

  setShowAlbumLength(showAlbumLength: boolean): TuneMarker {
    this.showAlbumLength = showAlbumLength;
    return this;
  }

  setTuneFont(tuneFont: string): TuneMarker {
    this.tuneFont = tuneFont;
    return this;
  }

  installAdditionalDrawMethods() {
    const additionalheight = 50;
    const artistColor = "#454545";
    const artistFontScalar = 0.7;
    const releaseDateAndLengthFontScalar = 0.5;
    const releaseDateAndLengthFont = "Oswald";
    const albumLengthAddativeScalar = 0.1;
    const trackFontFamily = "Raleway";
    const trackLineHeight = 50;
    const trackMaxWidthScalar = 16;
    const trackStartingYAdditionalFontSizeScalar = 3.5;

    // Album name
    this.addAdditionalDrawMethod((canvasAndContext: CanvasAndContext) => {
      canvasAndContext.setFont(this.getFontSize(), this.tuneFont);
      canvasAndContext.setFillStyle(offBlack);
      canvasAndContext.drawAlignedString(
        this.albumName,
        this.getFontSize(),
        this.getDominantColorsBottom() + this.getFontSize() + additionalheight,
        "start",
        this.getBorderPadding()
      );
    });

    // Artist
    this.addAdditionalDrawMethod((canvasAndContext: CanvasAndContext) => {
      const artistFontSize = this.getFontSize() * artistFontScalar;

      canvasAndContext.setFont(artistFontSize, this.tuneFont);
      canvasAndContext.setFillStyle(artistColor);
      canvasAndContext.drawAlignedString(
        this.artistName,
        this.getFontSize(),
        this.getDominantColorsBottom() +
          2 * this.getFontSize() +
          additionalheight,
        "start",
        this.getBorderPadding()
      );
    });

    // Release date
    this.addAdditionalDrawMethod((canvasAndContext: CanvasAndContext) => {
      const releaseDateFontSize =
        this.getFontSize() * releaseDateAndLengthFontScalar;
      canvasAndContext.setFont(
        releaseDateFontSize,
        releaseDateAndLengthFont,
        true
      );
      canvasAndContext.setFillStyle(offBlack);
      canvasAndContext.drawAlignedString(
        dayjs(this.releaseDate).format(this.releaseDateFormat),
        this.getFontSize(),
        this.getDominantColorsBottom() + releaseDateFontSize + additionalheight,
        "end",
        canvasAndContext.getCanvas().width - this.getBorderPadding()
      );
    });

    // Album length
    this.addAdditionalDrawMethod((canvasAndContext: CanvasAndContext) => {
      if (!this.showAlbumLength) return;

      const releaseDateFontSize =
        this.getFontSize() * releaseDateAndLengthFontScalar;

      canvasAndContext.setFont(
        releaseDateFontSize,
        releaseDateAndLengthFont,
        true
      );
      canvasAndContext.setFillStyle(offBlack);
      canvasAndContext.drawAlignedString(
        this.albumLength,
        this.getFontSize(),
        this.getDominantColorsBottom() +
          2 *
            this.getFontSize() *
            (releaseDateAndLengthFontScalar + albumLengthAddativeScalar) +
          additionalheight,
        "end",
        canvasAndContext.getCanvas().width - this.getBorderPadding()
      );
    });

    // Tracks
    this.addAdditionalDrawMethod((canvasAndContext: CanvasAndContext) => {
      if (this.tracks.length < 2) return;

      const trackStringFontSize = this.getFontSize() * 0.3;

      const tracksString = tracksToPosterString(
        this.tracks,
        this.showSongLengths,
        this.showTrackNumbers
      );

      canvasAndContext.setFont(trackStringFontSize, releaseDateAndLengthFont);
      const startingY =
        this.getDominantColorsBottom() +
        this.getAdditionalPaddingBelowImage() +
        trackStartingYAdditionalFontSizeScalar * this.getFontSize();
      canvasAndContext.setFillStyle(evenMoreOffBlack);

      canvasAndContext.extendCanvasAndDrawCenteredWrappedText(
        tracksString,
        (this.artworkSize.width * trackMaxWidthScalar) / 20,
        startingY,
        trackLineHeight,
        this.getBorderPadding(),
        trackStringFontSize,
        trackFontFamily
      );
    });
  }
}
