import CanvasAndContext from "../../components/CartographyCrafter/helpers/image/CanvasAndContext";

export const bulletPoint = "•";

export function splitTextIntoLines(
  canvasAndContext: CanvasAndContext,
  text: string,
  maxWidth: number
): string[] {
  const words = text.split(" ");
  let lines: string[] = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const testLine = currentLine + " " + words[i];
    const metrics = canvasAndContext.getContext().measureText(testLine);
    if (metrics.width > maxWidth && i > 0) {
      lines.push(currentLine);
      currentLine = words[i];
    } else {
      currentLine = testLine;
    }
  }
  lines.push(currentLine);
  return lines;
}
