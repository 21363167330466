import {
  evenMoreOffBlack,
  offWhite,
} from "../../../../shared/Constants/Colors";
import { splitTextIntoLines } from "../../../../shared/Helpers/StringHelpers";
import { ImageSize } from "./ImageSize";

export default class CanvasAndContext {
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;

  ///////////////
  // Construction
  ///////////////

  constructor(size: ImageSize) {
    this.canvas = document.createElement("canvas");
    this.canvas.width = size.width;
    this.canvas.height = size.height;
    const ctx = this.canvas.getContext("2d");
    if (!ctx) {
      throw new Error("Unable to get canvas context");
    }
    this.context = ctx;
  }

  ////////////
  // Factories
  ////////////

  static fromSize(size: ImageSize): CanvasAndContext {
    return new this(size);
  }

  static fromLength(length: number): CanvasAndContext {
    return new this({ width: length, height: length });
  }

  static fromWidthHeight(width: number, height: number): CanvasAndContext {
    return new this({ width, height });
  }

  ////////////
  // Accessors
  ////////////

  getCanvas(): HTMLCanvasElement {
    return this.canvas;
  }

  getContext(): CanvasRenderingContext2D {
    return this.context;
  }

  getCanvasAndContext(): [HTMLCanvasElement, CanvasRenderingContext2D] {
    return [this.canvas, this.context];
  }

  getBase64ImageString() {
    return this.canvas.toDataURL("image/png");
  }

  ///////////
  // Mutators
  ///////////

  fillBackground(color: string) {
    this.setFillStyle(color);
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
  }

  setFont(size: number, typeFace: string, bold: boolean = false) {
    const prefixModifier = bold ? "bold" : "";
    this.context.font = `${prefixModifier} ${size}px ${typeFace}`;
  }

  drawAlignedString(
    string: string,
    fontSize: number,
    startyingY: number,
    textAlign: CanvasTextAlign = "center",
    textX: number = this.canvas.width / 2
  ) {
    this.drawAlignedStrings([string], fontSize, startyingY, textAlign, textX);
  }

  drawAlignedStrings(
    strings: string[],
    fontSize: number,
    startingY: number,
    textAlign: CanvasTextAlign = "center",
    textX: number = this.canvas.width / 2
  ) {
    strings.forEach((line, index) => {
      if (index > 0) startingY += fontSize;
      this.context.textAlign = textAlign;
      this.context.fillText(line, textX, startingY);
    });
  }

  setFillStyle(color: string) {
    this.context.fillStyle = color;
  }

  /**
   * Creates a drawable region bounded by the provided dimensions and border radius.
   * Note, context.save() should be called prior to invocation and context.restore() should
   * be called following invocation.
   *
   * @param context the context
   * @param x the top left x coordinate
   * @param y the top left y coordinate
   * @param width the width of the region
   * @param height the height of the region
   * @param borderRadius the border radius of the region
   */
  drawRoundedRect(
    context: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    borderRadius: number
  ) {
    context.beginPath();
    context.moveTo(x + borderRadius, y);
    context.arcTo(x + width, y, x + width, y + height, borderRadius);
    context.arcTo(x + width, y + height, x, y + height, borderRadius);
    context.arcTo(x, y + height, x, y, borderRadius);
    context.arcTo(x, y, x + width, y, borderRadius);
    context.closePath();
    context.clip();
  }

  /**
   * Draws the provided image on the canvas, using borderPadding as the x,y to draw starting at.
   */
  drawRoundedImage(
    image: HTMLImageElement,
    borderPadding: number,
    borderRadius: number
  ) {
    this.context.save();
    this.drawRoundedRect(
      this.context,
      borderPadding,
      borderPadding,
      image.width,
      image.height,
      borderRadius
    );
    this.context.clip();
    this.context.drawImage(
      image,
      borderPadding,
      borderPadding,
      image.width,
      image.height
    );
    this.context.restore();
  }

  /**
   * Sets the dimensions of canvas.
   */
  setCanvasDimensions(size: ImageSize) {
    this.canvas.width = size.width;
    this.canvas.height = size.height;
  }

  extendCanvasAndDrawCenteredWrappedText(
    text: string,
    maxWidth: number,
    startY: number,
    lineHeight: number,
    bottomPadding: number,
    fontSize: number,
    fontFamily: string
  ) {
    const lines = splitTextIntoLines(this, text, maxWidth);
    const requiredHeight = startY + lines.length * lineHeight + bottomPadding;

    if (requiredHeight > this.canvas.height) {
      const offScreenCanvas = document.createElement("canvas");
      offScreenCanvas.width = this.canvas.width;
      offScreenCanvas.height = this.canvas.height;
      const offScreenContext = offScreenCanvas.getContext("2d");

      if (!offScreenContext) {
        console.error("Failed to create copy canvas");
        throw new Error("Failed to create copy canvas");
      }

      offScreenContext.drawImage(this.canvas, 0, 0);
      this.canvas.height = requiredHeight;
      this.context.fillStyle = offWhite;
      this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
      this.context.drawImage(offScreenCanvas, 0, 0);
    }

    const words = text.split(" ");
    let songLines = [];
    let currentLine = words[0];

    this.context.textAlign = "center";
    this.setFont(fontSize, fontFamily);
    this.setFillStyle(evenMoreOffBlack);

    for (let word = 1; word < words.length; word++) {
      const testLine = currentLine + " " + words[word];
      const metrics = this.context.measureText(testLine);
      if (metrics.width > maxWidth && word > 0) {
        songLines.push(currentLine);
        currentLine = words[word];
      } else {
        currentLine = testLine;
      }
    }

    // Remaining line
    songLines.push(currentLine);

    songLines.forEach((line, index) => {
      this.drawAlignedString(line, fontSize, startY + index * lineHeight);
    });
  }
}
