import { Box, Checkbox, Typography } from "@mui/material";
import CommonRouteContainer from "../../shared/Components/Container/Route/CommonRouteContainer";
import CommonDescription from "../../shared/Components/Text/CommonDescription";
import CommonHeader from "../../shared/Components/Text/CommonHeader";
import Well from "../../shared/Components/Well/Well";
import { evenMoreOffBlack } from "../../shared/Constants/Colors";
import { generalMaxWidth } from "../../shared/Constants/CommonConstants";
import { useAlert } from "../../shared/Context/AlertContext";
import { todos } from "./ITodo";
import SectionContainer from "../../shared/Components/Container/SectionContainer";
import { defaultShortenedPadding } from "../../shared/Components/Container/Route/CommonRouteContainerConstants";

export default function Todo() {
  // Context
  const { showErrorAlert } = useAlert();

  return (
    <CommonRouteContainer titlePrefix="ToDo">
      <CommonHeader>Website ToDo list</CommonHeader>
      <CommonDescription>
        This is prettier to look at than <Well>// todo</Well> comments
        throughout the code.
      </CommonDescription>
      <SectionContainer customBoxJustification="center">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            maxWidth: generalMaxWidth,
            width: "100%",
            padding: defaultShortenedPadding,
          }}
        >
          {todos.map((todo) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "start",
                  width: "100%",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Checkbox
                    checked={todo.completed}
                    onClick={() => showErrorAlert("Invalid credentials")}
                  />
                </Box>
                <Box
                  sx={{
                    flex: 10,
                  }}
                >
                  <CommonDescription>{todo.content}</CommonDescription>
                </Box>
              </Box>
            );
          })}
        </Box>
      </SectionContainer>
    </CommonRouteContainer>
  );
}
