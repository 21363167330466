import { Box, Typography } from "@mui/material";
import { evenMoreOffBlack, offBlack, offWhite } from "../../Constants/Colors";

interface SvgButtonProps {
  svg: React.ReactNode;
  text: string;
  onClick: () => void;
  fontSize?: string;
  width?: string;
}

export default function SvgButton({
  svg,
  text,
  onClick,
  fontSize = "15px",
  width,
}: SvgButtonProps) {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: evenMoreOffBlack,
        borderRadius: "200px",
        paddingX: "10px",
        paddingY: "6px",
        gap: "8px",
        paddingRight: "10px",
        transition: "all 0.2s linear",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: offBlack,
        },
        width: width,
      }}
    >
      {svg}
      <Typography
        sx={{
          color: offWhite,
          fontFamily: "Raleway",
          fontSize: fontSize,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
