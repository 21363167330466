import { createContext, useContext } from "react";
import { mapStepperSteps } from "../../components/CartographyCrafter/components/Steps/interfaces/MapStepperSteps";
import { tuneStepperSteps } from "../../components/TunePosters/Components/Steps/interfaces/TuneStepperSteps";
import { useAppDispatch, useAppSelector } from "../../redux/ReduxHooks";
import { setMapStep, setTuneStep } from "../../redux/metaSlice";
import { RootState } from "../../redux/store";

interface StepContextType {
  currentMapStep: number;
  currentMapStepName: string;
  currentTuneStep: number;
  currentTuneStepName: string;
  setCurrentMapStep: (step: number) => void;
  setCurrentTuneStep: (step: number) => void;
  onMapStep: (stepName: string) => boolean;
  onTuneStep: (stepName: string) => boolean;
}

const StepContext = createContext<StepContextType | undefined>(undefined);

interface StepProviderProps {
  children: React.ReactNode;
}

export default function StepProvider({ children }: StepProviderProps) {
  // Redux
  const currentMapStep = useAppSelector(
    (state: RootState) => state.meta.mapStep
  );
  const currentTuneStep = useAppSelector(
    (state: RootState) => state.meta.tuneStep
  );

  const dispatch = useAppDispatch();

  const currentMapStepName = getCurrentMapStepName();
  const currentTuneStepName = getCurrentTuneStepName();

  function setCurrentMapStep(step: number) {
    dispatch(setMapStep(step));
  }

  function setCurrentTuneStep(step: number) {
    dispatch(setTuneStep(step));
  }

  function getCurrentMapStepName() {
    const foundStep = mapStepperSteps.find(
      (step) => step.index === currentMapStep
    );
    if (!foundStep) return "unknown";
    return foundStep.name;
  }

  function getCurrentTuneStepName() {
    const foundStep = tuneStepperSteps.find(
      (step) => step.index === currentTuneStep
    );
    if (!foundStep) return "unknown";
    return foundStep.name;
  }

  function onMapStep(stepName: string) {
    const foundStep = mapStepperSteps.find(
      (step) => step.name.toLowerCase() === stepName.toLowerCase()
    );
    if (!foundStep) return false;
    return foundStep.index === currentMapStep;
  }

  function onTuneStep(stepName: string) {
    const foundStep = tuneStepperSteps.find(
      (step) => step.name.toLowerCase() === stepName.toLowerCase()
    );
    if (!foundStep) return false;
    return foundStep.index === currentTuneStep;
  }

  return (
    <StepContext.Provider
      value={{
        currentMapStep,
        currentMapStepName,
        currentTuneStep,
        currentTuneStepName,
        setCurrentMapStep,
        setCurrentTuneStep,
        onMapStep,
        onTuneStep,
      }}
    >
      {children}
    </StepContext.Provider>
  );
}

export const useStep = () => {
  const context = useContext(StepContext);
  if (context === undefined) {
    throw new Error("useStep must be used within a StepProvider context");
  }
  return context;
};
