const eveningStart = 18;
const afternoonStart = 12;

export function capitalizeFirstLetter(str: string): string {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeWords(sentence: string): string {
  return sentence
    .split(" ")
    .map((word) => capitalizeFirstLetter(word))
    .join(" ");
}

export function ellipseString(input: string, maxLength: number): string {
  if (input.length <= maxLength) {
    return input;
  }

  return input.substring(0, maxLength) + "...";
}

/**
 * Joins the provided url parts using a forward slash.
 */
export function joinUrlParts(...parts: string[]): string {
  return parts.join("/");
}

/**
 * Returns the a greeting based on the time of day such as evening, afternoon, or morning.
 */
export function getGreeting() {
  return `good ${getTimeOfDayClassification()}`;
}

export function getTimeOfDayClassification() {
  let hour = new Date().getHours();
  let greeting;

  if (hour >= eveningStart) {
    greeting = "evening";
  } else if (hour >= afternoonStart) {
    greeting = "afternoon";
  } else {
    greeting = "morning";
  }

  return greeting;
}

export function randomElement(list: any[], exclude?: any) {
  let filteredList = list;
  if (exclude !== null) {
    filteredList = list.filter((item) => item !== exclude);
  }
  return filteredList[Math.floor(Math.random() * filteredList.length)];
}
