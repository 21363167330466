import { useModal } from "query-string-modals";
import StandardModal from "../../shared/Components/Modal/StandardModal";
import { Box } from "@mui/material";
import { reloadFonts } from "../../app/FontLoader";
import CommonButton from "../../shared/Components/Buttons/CommonButton";
import NetlifyButton from "./NetlifyButton";
import ReduxButton from "./ReduxButton";
import { useAlert } from "../../shared/Context/AlertContext";
import fonts from "../../assets/fonts/GoogleFontFamilies.json";
import { onLocalHost } from "../../shared/Helpers/NetworkHelpers";
import DebugInformation from "./DebugInformation";
import {
  getDevicePixelRatio,
  getBrowserLanguage,
  getLocalTimezone,
  getUserAgent,
  likelyOnMobile,
} from "./DebugUtils";

export default function DebugModal() {
  // Constants
  const googleFonts = fonts as string[];

  // Context
  const { closeModal } = useModal();
  const { showInfoAlert } = useAlert();

  function getFormattedWindowInnerDimensions() {
    return window.innerHeight + "x" + window.innerWidth;
  }

  function getFormattedWindowScreenDimensions() {
    return window.screen.width + "x" + window.screen.height;
  }

  function getOnMobileLabel() {
    if (likelyOnMobile()) return "Likely on mobile";
    else return "Not likely on mobile";
  }

  return (
    <StandardModal
      title={"Debug"}
      cancelButtonText={"Close"}
      cancelButtonAction={() => closeModal()}
    >
      <DebugInformation>
        {`Viewport: ${getFormattedWindowInnerDimensions()}`}
      </DebugInformation>
      <DebugInformation>{`Screen: ${getFormattedWindowScreenDimensions()}`}</DebugInformation>
      <DebugInformation>{`Pixel ratio: ${getDevicePixelRatio()}`}</DebugInformation>
      <DebugInformation>{`Language: ${getBrowserLanguage()}`}</DebugInformation>
      <DebugInformation>{`Timezone: ${getLocalTimezone()}`}</DebugInformation>
      <DebugInformation>
        {"On " + (onLocalHost() ? "localhost" : "production")}
      </DebugInformation>
      <DebugInformation>
        {getUserAgent() + ` (${getOnMobileLabel()})`}
      </DebugInformation>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginY: "20px",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <NetlifyButton />
        <ReduxButton />
        <CommonButton
          fontSize="13px"
          fontFamily="Raleway"
          text="Reload fonts"
          width="200px"
          height="52px"
          onClick={() => {
            reloadFonts();
            showInfoAlert(`Reloaded ${googleFonts.length} fonts`);
          }}
        />
      </Box>
    </StandardModal>
  );
}
