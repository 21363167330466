import LaunchIcon from "@mui/icons-material/Launch";
import { Box, Typography } from "@mui/material";
import { offWhite, orange } from "../../shared/Constants/Colors";
import { useMinWidth } from "../../shared/Hooks/useMinWidth";
import { navbarColor } from "../Navigation/NavbarConstants";
import { footerHeight } from "./FooterConstants";
import FooterText from "./FooterText";
import FooterLaunch from "./FooterLaunch";

export default function Footer() {
  // Constants
  const transitionPoint = 750;
  const canShowRow = useMinWidth(transitionPoint);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: canShowRow ? "row" : "column",
          alignItems: canShowRow ? "center" : "center",
          justifyContent: canShowRow ? "space-between" : "center",
          width: "100%",
          height: `${footerHeight}px`,
          bottom: 0,
          backgroundColor: navbarColor,
          padding: "20px",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: useMinWidth(transitionPoint) ? "column" : "row",
            alignItems: "start",
            justifyContent: "center",
            gap: useMinWidth(transitionPoint) ? "5px" : "25px",
          }}
        >
          <FooterLaunch text="EULA" link="/eula" />
          <FooterLaunch text="Credits" link="/credits" />
        </Box>
        <FooterText>
          Copyright {new Date().getFullYear()}. Natche Group, LLC. All rights
          reserved.
        </FooterText>
        <Box />
      </Box>
    </Box>
  );
}
