import { createContext, useContext, useEffect, useState } from "react";
import { loadGoogleMapsApis } from "../../components/CartographyCrafter/helpers/google/GoogleHelpers";

const ScriptLoaderContext = createContext({
  isGoogleMapsLoaded: false,
});

interface ScriptLoaderProviderProps {
  children: React.ReactNode;
}

export default function ScriptLoaderProvider({
  children,
}: ScriptLoaderProviderProps) {
  const [isGoogleMapsLoaded, setGoogleMapsLoaded] = useState<boolean>(false);

  useEffect(() => {
    loadGoogleMapsApis().then((loaded) => setGoogleMapsLoaded(loaded));
  }, []);

  return (
    <ScriptLoaderContext.Provider value={{ isGoogleMapsLoaded }}>
      {children}
    </ScriptLoaderContext.Provider>
  );
}

export const useScriptLoader = () => {
  const context = useContext(ScriptLoaderContext);
  if (context === undefined) {
    throw new Error(
      "useScriptLoader must be used within a ScriptLoaderProvider"
    );
  }
  return context;
};
