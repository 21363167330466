import { Box } from "@mui/material";
import Hamburger from "hamburger-react";
import { useState } from "react";
import { orange } from "../../shared/Constants/Colors";

interface MenuButtonProps {
  toggled: boolean;
  setToggled: () => void;
}

export default function MenuButton({ toggled, setToggled }: MenuButtonProps) {
  // State
  const [mouseIn, setMouseIn] = useState<boolean>(false);

  return (
    <Box
      onMouseEnter={() => setMouseIn(true)}
      onMouseLeave={() => setMouseIn(false)}
      zIndex={1000}
    >
      <Hamburger
        size={32}
        duration={0.3}
        color={mouseIn ? orange : "white"}
        toggled={toggled}
        toggle={setToggled}
        hideOutline
        rounded
      />
    </Box>
  );
}
