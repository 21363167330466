import { Scale } from "../google/Scale";

export interface ScaleCropFactor {
  scale: number;
  cropBottomPixels: number;
}

export const scaleCropFactors: ScaleCropFactor[] = [
  {
    scale: Scale.ONE,
    cropBottomPixels: 35,
  },
  {
    scale: Scale.TWO,
    cropBottomPixels: 44,
  },
];

export function getCropFactorForScale(scale: Scale) {
  const foundCropFactor = scaleCropFactors.find(
    (scaleCropFactor) => scaleCropFactor.scale === scale
  );

  if (!foundCropFactor) {
    throw new Error("Failed to find scale crop factor for scale: " + scale);
  }

  return foundCropFactor.cropBottomPixels;
}
