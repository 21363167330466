import { useMinWidth } from "../../../Hooks/useMinWidth";
import {
  canShowFullPaddingBreakpoint,
  defaultFullPadding,
  defaultShortenedPadding,
} from "./CommonRouteContainerConstants";

export const useCommonRouteContainerVerticalPadding = (
  providedPaddingY?: string
) => {
  const showFullPadding = useMinWidth(canShowFullPaddingBreakpoint);

  if (providedPaddingY !== "determine") return providedPaddingY;

  return showFullPadding ? defaultFullPadding : defaultShortenedPadding;
};
