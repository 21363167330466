interface WellProps {
  children?: string;
}

export default function Well({ children }: WellProps) {
  return (
    <pre
      style={{
        background: "#ddd",
        border: "1px solid #ddd",
        padding: "3px",
        borderRadius: "5px",
        fontFamily: "monospace",
        overflowX: "auto",
        display: "inline-block",
        maxWidth: "100%",
        fontSize: "0.8em",
        lineHeight: "1",
        margin: "0",
        verticalAlign: "middle",
      }}
    >
      <code>{children}</code>
    </pre>
  );
}
