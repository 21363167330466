import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ImageOutputType } from "../components/CartographyCrafter/helpers/image/ImageOutputType";

interface ExportMapState {
  proposedFileName: string;
  proposedExtension: string;
  proposedImage: string;
}

const initialState: ExportMapState = {
  proposedFileName: "",
  proposedExtension: ImageOutputType.PNG,
  proposedImage: "",
};

export const exportMapSlice = createSlice({
  name: "exportMap",
  initialState,
  reducers: {
    setProposedFileName: (state, action: PayloadAction<string>) => {
      state.proposedFileName = action.payload;
    },
    setProposedExtension: (state, action: PayloadAction<string>) => {
      state.proposedExtension = action.payload;
    },
    setProposedImage: (state, action: PayloadAction<string>) => {
      state.proposedImage = action.payload;
    },
    resetExportMapSlice: () => initialState,
  },
});

export const {
  setProposedFileName,
  setProposedExtension,
  setProposedImage,
  resetExportMapSlice,
} = exportMapSlice.actions;
export default exportMapSlice.reducer;
