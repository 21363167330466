import { ReactComponent as NetlifySvg } from "../../assets/svgs/Netlify.svg";
import SvgButton from "../../shared/Components/Buttons/SvgButton";

export default function NetlifyButton() {
  const linkTo =
    "https://app.netlify.com/sites/nathancheshire/deploys?filter=main";

  return (
    <SvgButton
      fontSize="13px"
      text="View deployments"
      width="200px"
      svg={
        <NetlifySvg
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "200px",
          }}
        />
      }
      onClick={() => window.open(linkTo, "_blank")}
    />
  );
}
