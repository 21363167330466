import { Typography } from "@mui/material";
import { evenMoreOffBlack } from "../../Constants/Colors";
import useScalableSizes from "../../Hooks/useScalableSizes";

interface CommonHeaderProps {
  children: React.ReactNode | string;
  textAlign?: string;
  paddingTop?: string;
  color?: string;
}

export default function  CommonHeader({
  children,
  textAlign = "center",
  paddingTop = "40px",
  color = evenMoreOffBlack,
}: CommonHeaderProps) {
  const { headerTextSize } = useScalableSizes();

  return (
    <Typography
      sx={{
        fontFamily: "Prompt",
        fontWeight: "bold",
        fontSize: headerTextSize,
        color: color,
        textAlign: textAlign,
        paddingTop: paddingTop,
      }}
    >
      {children}
    </Typography>
  );
}
