import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface SpotifyAuthenticationState {
  token: string;
  expiresAt: number;
}

const initialState: SpotifyAuthenticationState = {
  token: "",
  expiresAt: 0,
};

export const spotifyAuthenticationSlice = createSlice({
  name: "spotifyAuthentication",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setExpiresAt: (state, action: PayloadAction<number>) => {
      state.expiresAt = action.payload;
    },
    resetSpotifyAuthenticationSlice: () => initialState,
  },
});

export const { setToken, setExpiresAt, resetSpotifyAuthenticationSlice } =
  spotifyAuthenticationSlice.actions;
export default spotifyAuthenticationSlice.reducer;
