export enum ThemeIcon {
  Aubergine = "aubergine",
  Dark = "dark",
  Night = "night",
  Retro = "retro",
  Silver = "silver",
  Standard = "standard",
  LostInTheDesert = "lost in the desert",
  Grayscale = "grayscale",
  AssassinsCreed = "assassins creed",
  Vintage = "vintage",
  Subtle = "subtle",
}
