import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface MapVisualizationState {
  studentHeatFall2021: boolean;
  studentHeatSpring2022: boolean;
  chosenStatesForRoutes: string[];
  byNetId: string;
  chosenStyle: string;
  nationWidePaths: boolean;
  zoom: number;
  pitch: number;
  latitude: number;
  longitude: number;
  streetViewImage: string;
  pathWidth: number;
}

// Default constants
export const defaultMapboxZoom = 5;
export const defaultMapboxPitch = 0;
export const defaultMapboxLatitude = 33.45505;
export const defaultMapboxLongitude = -88.79365;
export const defaultPathWidth = 5;

const initialState: MapVisualizationState = {
  studentHeatFall2021: false,
  studentHeatSpring2022: false,
  chosenStatesForRoutes: [],
  byNetId: "",
  chosenStyle: "Streets",
  nationWidePaths: false,
  zoom: defaultMapboxZoom,
  pitch: defaultMapboxPitch,
  latitude: defaultMapboxLatitude,
  longitude: defaultMapboxLongitude,
  streetViewImage: "",
  pathWidth: defaultPathWidth,
};

export const mapVisualizationSlice = createSlice({
  name: "mapVisualizations",
  initialState,
  reducers: {
    setStudentHeatFall2021: (state, action: PayloadAction<boolean>) => {
      state.studentHeatFall2021 = action.payload;
    },
    setStudentHeatSpring2022: (state, action: PayloadAction<boolean>) => {
      state.studentHeatSpring2022 = action.payload;
    },
    setChosenStatesForRoutes: (state, action: PayloadAction<string[]>) => {
      state.chosenStatesForRoutes = action.payload;
    },
    setByNetId: (state, action: PayloadAction<string>) => {
      state.byNetId = action.payload;
    },
    setChosenStyle: (state, action: PayloadAction<string>) => {
      state.chosenStyle = action.payload;
    },
    setNationWidePaths: (state, action: PayloadAction<boolean>) => {
      state.nationWidePaths = action.payload;
    },
    setZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
    setPitch: (state, action: PayloadAction<number>) => {
      state.pitch = action.payload;
    },
    setLatitude: (state, action: PayloadAction<number>) => {
      state.latitude = action.payload;
    },
    setLongitude: (state, action: PayloadAction<number>) => {
      state.longitude = action.payload;
    },
    setStreetViewImage: (state, action: PayloadAction<string>) => {
      state.streetViewImage = action.payload;
    },
    setPathWidth: (state, action: PayloadAction<number>) => {
      state.pathWidth = action.payload;
    },
    resetMapVisualizationSlice: () => initialState,
  },
});

export const {
  setStudentHeatFall2021,
  setStudentHeatSpring2022,
  resetMapVisualizationSlice,
  setChosenStatesForRoutes,
  setByNetId,
  setChosenStyle,
  setNationWidePaths,
  setZoom,
  setPitch,
  setLatitude,
  setLongitude,
  setStreetViewImage,
  setPathWidth,
} = mapVisualizationSlice.actions;
export default mapVisualizationSlice.reducer;
